 //COLORES 
 export interface Theme {
     id: number,
     name: string;
     properties: any;
 }

 export const themes = [
     {
        id:2,
        name:"motrio",
        properties:{
            // PRIMARY
            "--primary-color": "#BE1423",
            "--primary-color-transparent":"rgba(190, 20, 35, 0.8)",

            // TOP BAR
            "--top-bar-background-color":"#000",
            "--top-bar-font-color":"#fff",

            // NAV BAR
            "--nav-background-color":"#BE1423",
            "--nav-font-color":"#fff",
            "--nav-line-color":"#fff",
            "--nav-font-color-active":"#fff",

            //FLAGS
            "--ribbon-bg-color":"#BE1423",
            "--ribbon-color":"#fff",

            // GENERAL
            "--white-color":"#fff",
            "--white-color-transparent":"rgba(255, 255, 255, 0.8)",
            "--black-color":"#000",
            "--light-grey-color":"#dedede",
            "--grey-color":"#b6b6b8",
            "--dark-grey-color":"#4c4c4c",
            "--car-icons-color":"#BE1423",

            // FOOTER
            "--footer-background-color":"#BE1423",
            "--footer-font-color":"#fff",
            "--bottom-bar-background-color":"#000",
            "--bottom-bar-font-color":"#fff",

            //APPOITNMENT
            "--appointment-icons-color":"#fff",
            "--appointment-font-color":"#fff",
            "--appointment-icons-active-color":"#BE1423",
            "--appointment-font-active-color":"#000",

            //WHO WE ARE
            "--who-we-are-background-color":"#BE1423",
            "--who-we-are-font-color":"#fff",
            "--who-we-are-btn-background-color":"#000",
            "--who-we-are-btn-font-color":"#fff",

            //CONTACT
            "--form-border-color":"#BE1423",
            "--form-btn-background-color":"#BE1423",
            "--form-btn-font-color":"#fff",
            "--form-btn-active-background-color":"#4c4c4c",
            "--form-btn-active-font-color":"#fff",
            
            //BUTTONS
            "--primary-button-background-color":"#BE1423",
            "--primary-button-font-color":"#fff",
            "--secondary-button-background-color":"#BE1423",
            "--secondary-button-font-color":"#fff",

            // FUENTES
            "--font-teko": "Teko,sans-serif",
            "--font-raleway": "Raleway, Arial, sans-serif"
        }
    },
    {
        id:3,
        name:"renault",
        properties:{
            // PRIMARY
            "--primary-color": "#ffce33",
            "--primary-color-transparent":"rgba(255, 206, 51, 0.8)",
            
            // TOP BAR
            "--top-bar-background-color":"#1f2532",
            "--top-bar-font-color":"#ffce33",

            // NAV BAR
            "--nav-background-color":"#fff",
            "--nav-font-color":"#000",
            "--nav-line-color":"#ffce33",
            "--nav-font-color-active":"#000",

            //FLAGS
            "--ribbon-bg-color":"#ffce33",
            "--ribbon-color":"#000",

            // GENERAL
            "--white-color":"#fff",
            "--white-color-transparent":"rgba(255, 255, 255, 0.8)",
            "--black-color":"#000",
            "--light-grey-color":"#dedede",
            "--grey-color":"#b6b6b8",
            "--dark-grey-color":"#4c4c4c",
            "--car-icons-color":"#1f2532",

            // FOOTER
            "--footer-background-color":"#1f2532",
            "--footer-font-color":"#fff",
            "--bottom-bar-background-color":"#fff",
            "--bottom-bar-font-color":"#000",

            //APPOITNMENT
            "--appointment-icons-color":"#1f2532",
            "--appointment-font-color":"#000",
            "--appointment-icons-active-color":"#ffce33",
            "--appointment-font-active-color":"#fff",

            //WHO WE ARE
            "--who-we-are-background-color":"#1f2532",
            "--who-we-are-font-color":"#fff",
            "--who-we-are-btn-background-color":"#ffce33",
            "--who-we-are-btn-font-color":"#000",
            
            //CONTACT
            "--form-border-color":"#1f2532",
            "--form-btn-background-color":"#1f2532",
            "--form-btn-font-color":"#fff",
            "--form-btn-active-background-color":"#ffce33",
            "--form-btn-active-font-color":"#000",

            //BUTTONS
            "--primary-button-background-color":"#1f2532",
            "--primary-button-font-color":"#fff",
            "--secondary-button-background-color":"#ffce33",
            "--secondary-button-font-color":"#000",

            // FUENTES
            "--font-teko": "Teko,sans-serif",
            "--font-raleway": "Raleway, Arial, sans-serif"
        }
    },
    {
        id:4,
        name:"dacia",
        properties:{
            // PRIMARY
            "--primary-color": "#646b52",
            "--primary-color-transparent":"rgba(100, 107, 82, 0.8)",
            
            // TOP BAR
            "--top-bar-background-color":"#000000",
            "--top-bar-font-color":"#646b52",

            // NAV BAR
            "--nav-background-color":"#fff",
            "--nav-font-color":"#000",
            "--nav-line-color":"#646b52",
            "--nav-font-color-active":"#000",

            //FLAGS
            "--ribbon-bg-color":"#000000",
            "--ribbon-color":"#fff",

            // GENERAL
            "--white-color":"#fff",
            "--white-color-transparent":"rgba(255, 255, 255, 0.8)",
            "--black-color":"#000",
            "--light-grey-color":"#dedede",
            "--grey-color":"#b6b6b8",
            "--dark-grey-color":"#4c4c4c",
            "--car-icons-color":"#646b52",

            // FOOTER
            "--footer-background-color":"#000000",
            "--footer-font-color":"#fff",
            "--bottom-bar-background-color":"#fff",
            "--bottom-bar-font-color":"#000",

            //APPOITNMENT
            "--appointment-icons-color":"#1f2532",
            "--appointment-font-color":"#000",
            "--appointment-icons-active-color":"#646b52",
            "--appointment-font-active-color":"#000",

            //WHO WE ARE
            "--who-we-are-background-color":"#000000",
            "--who-we-are-font-color":"#fff",
            "--who-we-are-btn-background-color":"#646b52",
            "--who-we-are-btn-font-color":"#fff",

            //CONTACT
            "--form-border-color":"#000000",
            "--form-btn-background-color":"#000000",
            "--form-btn-font-color":"#fff",
            "--form-btn-active-background-color":"#646b52",
            "--form-btn-active-font-color":"#fff",

            //BUTTONS
            "--primary-button-background-color":"#000000",
            "--primary-button-font-color":"#fff",
            "--secondary-button-background-color":"#646b52",
            "--secondary-button-font-color":"#fff",

            // FUENTES
            "--font-teko": "Teko,sans-serif",
            "--font-raleway": "Raleway, Arial, sans-serif"
        }
    }];

 export const multisite: Theme = {
    id:2,
    name:"motrio",
    properties:{
        "--primary-color": "#BE1423",
        "--primary-color-transparent":"rgba(190, 20, 35, 0.8)",
        "--white-color":"#fff",
        "--white-color-transparent":"rgba(255, 255, 255, 0.8)",
        "--black-color":"#000",
        "--light-grey-color":"#dedede",
        "--grey-color":"#b6b6b8",
        "--dark-grey-color":"#4c4c4c",
        // FUENTES
        "--font-teko": "Teko,sans-serif",
        "--font-raleway": "Raleway, Arial, sans-serif"
    }
 };
