import { Component, OnInit, Input } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { AppService } from 'src/app/services/app.service';
import { EmbedVideoService } from 'ngx-embed-video';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faTruckMonster } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {

  promotions = [];
  slider = [];
  videoURL;
  height;
  videoId;
  youtubeURL = 'https://www.youtube.com/watch?v=KmPPOjo8fUg';

  constructor(
    public _ss: SharingService,
    private _ap: AppService,
    private embedService:EmbedVideoService
  ) { }

  customOptions: OwlOptions = {
    autoplay:true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    loop: true,
    animateIn:"fadeIn",
    animateOut:"fadeOut",
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    items:1,
    autoHeight: true,
    autoWidth:true,
    nav: true
  }
  
/*
  customOptions: any = {
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    loop: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    mouseDrag: true,
    video:true,
    touchDrag: true,
    pullDrag: false,
    scrollDrag: true,
    dots: true,
    navSpeed: 700,
    items:1,
    autoHeight:true,
    autoHeightClass: 'owl-height',
    nav: false
  }
*/
  ngOnInit(): void {
    this.height = window.innerHeight - 120;

    this.videoURL = this.embedService.embed(this.youtubeURL, {
      query: { portrait: 0, color: '333' },
      attr: { width: 1920, height: this.height }
    });

    this.getWorkshopSlider();
    this.getFeaturedBrandPromotions();
  }

  getWorkshopSlider(){
    console.log('Cargando slider....');
    this._ap.getWorkshopSlider().subscribe((response)=>{
      console.log(response);
      if(response.result == 'OK'){
        console.log(response.msg);
        console.log(response.data);
        this.slider = response.data;
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error!");
      }
    },(err)=>{
      console.log("Se ha producido un error: "+err);
    });
  }



  getFeaturedBrandPromotions(){
    console.log('Cargando promociones....');
    this._ap.getFeaturedBrandPromotions().subscribe((response)=>{
      console.log(response);
      if(response.result == 'OK'){
        console.log(response.msg);
        this.promotions = response.data;
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error!");
      }
    },(err)=>{
      console.log("Se ha producido un error: "+err);
    });
  }

  handlerOfSomeEvent(){

  }

}
