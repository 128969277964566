<agm-map #AgmMap [latitude]="lat" [longitude]="lng" [zoom]="zoom" (idle)="mapIdle()">
    <agm-marker (markerClick)="showInfo(infoWindow)" *ngFor="let center of centers" [latitude]="center.latitude" [longitude]="center.longitude" [label]="center.name">
        <agm-info-window #infoWindow>
            <div class="contact-bar">
                <a class="btn-contact" href="tel:{{center.phone}}">
                    <fa-icon [icon]="faPhone"></fa-icon>
                </a>
                <a class="btn-contact email" href="mailto:{{center.email}}">
                    <fa-icon [icon]="faEnvelope"></fa-icon>
                </a>
            </div>
            <div class="info-content">
                <p [innerHTML]="center.description" *ngIf="center.description"></p>
                <p *ngIf="!center.description"><b>! No hay descripción !</b></p>
                <b>Direccion:</b>
                <div class="row">
                    {{center.street}}, {{center.number}}<br />{{center.council}} {{center.province}}
                </div>
            </div>
            <a class="btn-booking" (click)="showAppointmentCenter(center)">PEDIR CITA</a>
        </agm-info-window>
    </agm-marker>
</agm-map>