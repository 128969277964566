import { Component, OnInit } from '@angular/core';
import { VEHICLES, WORKSHOP_ICONS } from '../../../../constants';
import { AppService } from 'src/app/services/app.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SharingService } from 'src/app/services/sharing.service';
import { relativeTimeThreshold } from 'moment';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-vehicle-slider',
  templateUrl: './vehicle-slider.component.html',
  styleUrls: ['./vehicle-slider.component.scss']
})
export class VehicleSliderComponent implements OnInit {
  customOptions: any = {
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: false
  }
  id;
  vehicles;
  buttonText = 'VER MÁS';
  faPlus = faPlus;
  fuel;
  engine;
  color;
  cardoor;
  location;
  calendar;
  bodywork;
  kilometers;
  icons = WORKSHOP_ICONS;
  constructor(
    private _ap: AppService,
    public _ss:SharingService
  ) {}

  ngOnInit(){
    console.log('aquiii la config');
    console.log(this._ss.workShopConfig);
    this.fuel = this.icons.find(x => x.title == 'fuel').pathIcon;
    this.engine = this.icons.find(x => x.title == 'engine').pathIcon;
    this.kilometers = this.icons.find(x => x.title == 'speedometer').pathIcon;
    this.color = this.icons.find(x => x.title == 'color').pathIcon;
    this.cardoor = this.icons.find(x => x.title == 'car').pathIcon;
    this.calendar = this.icons.find(x => x.title == 'calendar').pathIcon;
    this.location = this.icons.find(x => x.title == 'location').pathIcon;
    this.bodywork = this.icons.find(x => x.title == 'car').pathIcon
    this.getFeaturedVehicles();
  }

  getFeaturedVehicles(){
    this._ap.getFeaturedVehicles().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.vehicles = response.data;
        console.log("coches destacados");
        console.log(response.data);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error!");
      }
    });
  }

}

