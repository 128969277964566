<div id="promotion-container" *ngIf="promotions.length == 0 && slider.length == 0 && _ss.acceskey != null">
    <div class="no-slider">
        <span class="alert">Actualmente no tiene ningún slider activo!</span>
    </div>
</div>
<!-- SLIDER TALLER -->
<div id="promotion-container" *ngIf="promotions.length == 0">
    <owl-carousel-o [options]="customOptions" #owlCar>
      
        <ng-container  *ngFor="let slide of slider"> 
            <ng-template carouselSlide *ngIf="slide.status">
                <div class="image"  [ngStyle]="{'background-image':'url('+ _ss.imgURL + slide.image + ')'}">
                    <div class="overlay">
                        <div class=content>
                            <h1 class="title">{{ slide.title }}</h1>
                            <p [innerHtml]="slide.description"></p>
                            <div class="buttons" *ngIf="slide.active_contact">
                                    <a class="btn btn-primary btn-lg" id="email" *ngIf="_ss.workShopConfig.email">CONTÁCTANOS</a>
                                    <a class="btn btn-primary btn-lg" id="phone" *ngIf="_ss.workShopConfig.email">LLÁMANOS</a>                                                        
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <!--
        <ng-container>
            <ng-template carouselSlide>
                <div [innerHtml]="videoURL"></div>
            </ng-template>
        </ng-container>
        -->
        
              
     
    </owl-carousel-o>
    <app-workshop-appointment *ngIf="_ss.getModule(6)"></app-workshop-appointment>
</div>

<!-- SLIDER PROMOCIONES -->
<div id="promotion-container" *ngIf="promotions.length > 0">
    <owl-carousel-o [options]="customOptions" #owlCar>
        <ng-container  *ngFor="let promotion of promotions" >
            <ng-template carouselSlide>
                <div class="image" [ngStyle]="{'background-image':'url('+ _ss.imgURL + promotion.image + ')'}">
                    <div class="overlay">
                        <div class=content>
                            <h1 class="title">{{ promotion.title }}</h1>
                            <h2>{{promotion.summary}}</h2>
                            <div class="buttons">
                                    <a class="btn btn-primary btn-lg" id="email" *ngIf="_ss.workShopConfig.email" [routerLink]="['/promociones-marca/', promotion.id]">VER PROMOCIÓN</a>                            
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
    <app-workshop-appointment *ngIf="_ss.getModule(6)"></app-workshop-appointment>
</div>

