import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-promotion-details',
  templateUrl: './promotion-details.component.html',
  styleUrls: ['./promotion-details.component.scss']
})
export class PromotionDetailsComponent implements OnInit {

  promotion;
  sectionPromotions;
  promotionId;
  id;
  faPlus = faPlus;
  featuredPromotion;
  customOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: false
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private _ap: AppService,
    public _ss: SharingService,
    private router: Router
  ) {
  }

  ngOnInit() {
    let promotionType = this.router.url.slice(1, this.router.url.length);
    promotionType = promotionType.split('/')[0];
    console.log(promotionType);
    this.activatedRoute.params.subscribe(
      params => {
         this.promotionId = +params['id'];
         if(promotionType == 'promociones'){
          this.getPromotionDetail();
         }else{
           console.log('cargando promocion de marca');
           this.getPromotionBrandDetails();
         }
        this.getFeaturedPromotion();
      }
    );
  }


  getFeaturedPromotion(){
    this._ap.getFeaturedPromotions().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.featuredPromotion = response.data;
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  getPromotionDetail(){
    this._ap.getPromotionsDetails(this.promotionId).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
          this.promotion = response.data;
        console.log(this.promotion);
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  getPromotionBrandDetails(){
    this._ap.getBrandPromotionDetail(this.promotionId).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.promotion = response.data;
        console.log('la promocion');
        console.log(this.promotion);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  routerToPromotion(id){
    this.router.navigate(['promociones/'+id]);
  }
}

