import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {

  page = {
    id_page_type: 5,
    name: null,
    banner_title: null,
    banner_image: null,
    content_title: null,
    content_subtitle: null,
    content_description: null,
    content_image: null
  }

  constructor(
    public _ss: SharingService
  ) { }

  ngOnInit(): void {
  }

}
