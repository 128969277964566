import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-workshop-promotions',
  templateUrl: './workshop-promotions.component.html',
  styleUrls: ['./workshop-promotions.component.scss'],
})
export class WorkshopPromotionsComponent implements OnInit {

  faPlus = faPlus;
  id;
  promotions = [];

  page = {
    id_page_type: 3,
    name: null,
    banner_title: null,
    banner_image: null,
    content_title: null,
    content_subtitle: null,
    content_description: null,
    content_image: null
  };

  config = {
      itemsPerPage: 9,
      currentPage: 1,
      totalItems: this.promotions.length
    };

  constructor(
    private _ap: AppService,
    public _ss: SharingService) {
      this._ss.getLocalStorage(); 
      this._ss.checkSiteMaintenance();
      this._ss.setTitle('Promociones');
  }

  ngOnInit(): void {
    this.getInfoPage();
    this.getBrandPromotions();
    this.getPromotions();
    console.log(this.promotions);
  }

  getInfoPage(){
    this._ap.getInfoPageDetails(this.page.id_page_type).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.page = response.data[0];
        console.log(this.page);
      }else{
        console.log(response.msg);
      }
    });
  }

  getBrandPromotions(){
    this._ap.getBrandPromotions().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        let promotions = response.data;
        promotions.forEach(promotion => {
          console.log(promotion);
          promotion.id_workshop = null;
          this.promotions.push(promotion);
        });
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  getPromotions(){
    console.log(this.promotions);
    this._ap.getSectionPromotions().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        let promotions = response.data;
        promotions.forEach(promotion => {
          console.log(promotion);
          promotion.summary = null;
          promotion.id_brand = null;
          this.promotions.push(promotion);
        });
        console.log(this.promotions);
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  pageChanged(event){
    this.config.currentPage = event;
  }

}
