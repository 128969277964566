import { Component, OnInit} from '@angular/core';
import { FOOTER_WORKSHOP } from '../../../constants';
import { faPhone, faEnvelope, faMapMarkerAlt, faClock, faCopyright, faPlus,   } from '@fortawesome/free-solid-svg-icons';
import { AppService } from 'src/app/services/app.service';
import { SharingService } from 'src/app/services/sharing.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{

  constructor(
    private _ap: AppService,
    public _ss: SharingService,
    private router:Router
  ) { }
  
  // ICONS
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faMapMarkerAlt = faMapMarkerAlt;
  faClock = faClock;
  faCopyright =faCopyright;
  faPlus = faPlus;

  // FOOTER
  titlelinks = 'Enlaces';
  titlevehicles = 'Vehículos destacados'
  socialIcons = FOOTER_WORKSHOP.social;
  socialNetworks = [];
  vehicles;
 
  ngOnInit() {
    this.getFeaturedVehicles();
    this.getSocialNetworks();
  }

  getFeaturedVehicles(){
    this._ap.getFeaturedVehicles().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        console.log("los vehiculos del coche");
        this.vehicles = response.data;
        console.log(response.data);
      }else{
        console.log(response.msg);
      }
    });
  }


  getSocialNetworks(){
    console.log("Cargando redes sociales");
      this._ap.getSocialNetworks().subscribe((response)=>{
        if(response.result == 'OK'){
          console.log(response.msg);
          console.log(response);
          this.socialNetworks = response.data;
          this.socialNetworks.forEach(social => {
            social.image = this.socialIcons.find(x => x.id == social.id_social_network).image;
          });
        }else{
          console.log("Se ha producido un error!");
          console.log(response);
        }
    });
  }

}
