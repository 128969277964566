import { Component, Inject, HostListener } from '@angular/core';
import { SharingService } from './services/sharing.service';
import { DOCUMENT } from '@angular/common';
import { faChevronUp} from '@fortawesome/free-solid-svg-icons';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  windowScrolled: boolean;
  title = 'Multisite';
  faChevronUp = faChevronUp;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public _ss:SharingService,
    private _ap: AppService
  ){
  }

    @HostListener("window:scroll", [])
    onWindowScroll() {
        if (window.pageYOffset > 200 || document.body.scrollTop > 200) {
            this.windowScrolled = true;
        } 
       else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }

    ngOnInit() {
    }
    
    scrollToTop() {
      (function smoothscroll() {
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 8));
          }
      })();
  }
  
}
