import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { GoogleMapsAPIWrapper, AgmMap, MapsAPILoader} from '@agm/core';
import { SharingService } from 'src/app/services/sharing.service';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {

  @Input() centers;
  faPhone = faPhone;
  faEnvelope = faEnvelope;

  @ViewChild('AgmMap') agmMap: AgmMap;
  bounds = null;
  lat = 41.399115;
  lng = 2.160962;
  zoom = 5;

  public icon = {
    image:"../../assets/",
    width:"20",
    height:"20", 
  }

  constructor(
    public mapsAPILoader:MapsAPILoader,
    public _ss: SharingService
  ) { }

  ngOnInit(): void {
    console.log(this.centers);
  }

  ngAfterViewInit() {
    this.setBounds();
  }

  setBounds(){
    console.log("cargando fitbouds de los centros");
		this.mapsAPILoader.load().then(() =>{
			this.bounds = new window['google'].maps.LatLngBounds();
      this.centers.forEach(center => {
        console.log("Centrooo");
        console.log(center);
				this.bounds.extend(new window['google'].maps.LatLng(center.latitude,center.longitude));
      });
    });
  }

  mapIdle() {
    console.log('idle');
  }
  
  protected mapReady(map) {
    map.fitbounds(this.bounds);
  }

  showInfo(infoWindow){

  }

  showAppointmentCenter(center){
    console.log('Mostrando el centro');
    console.log(center);
    this._ss.setCenterId(center.id_driver360_center);
    setTimeout(() => {
      this._ss.appointmentStatus = true;
    },100);
  }

}
