import { Injectable } from '@angular/core';
import { BANNER, BANNER_BELOW, BANNER_UP } from '../constants';
import { URL } from '../config';
import { SharingService } from './sharing.service';
import { Http, Response, Headers} from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';




@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    public _ss: SharingService,
    private http: Http,
  ) { }

  private publicURL = URL.host_backend+ URL.endpoint_public;  // URL public end points
  private adminURL = URL.host_backend+ URL.endpoint_admin; // URL admin end points
  private driverURL = URL.host_backend+ URL.endpoint_driver; // URL driver end points
  private qcarURL = URL.host_backend+ URL.endpoint_qcar; // URL qcar end points

  featuredPromotions;
  vehicleData;

  banner = BANNER;
  banner_up = BANNER_UP;
  banner_below = BANNER_BELOW;

  //section_who_we_are = SECTION_WHO_WE_ARE;

  private getHeadersPublic() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('api-key', '@?Uq?DKYLd3bY_e*N%L46YKQ%M!ZcwABKAS_%mRBy%KD8D7#dagx5*A*hv2UR3RwufLvraDnxYmS7m+dE!_nybvNg^bZgHQbZ=Yp#g#Y6yDgLY7H!dmH=ZEuZf#A9yRD^kLw8H8s9MGQxCCWLWHN6-CCFYY$@uC24EXp*gm3==bLWvM6MUm%p9kkSyhNqegv3@qW@DN%?ux4zcLCv$W8V=CbBJ4cS@XA%CBwEA=gW?6CKMUE^%ACB_&*EchqV#Sq');
    headers.append('Authorization', this._ss.getWorkshopSettings() ? this._ss.getWorkshopSettings().token : '');
    return headers;
  } 

  // Método para Obtener las páginas activas
  getWorkshopPages(){
    return this.http.get(this.publicURL + 'workshop/'+this._ss.getWorkshopSettings().id_workshop+'/brand/' + this._ss.getWorkshopSettings().id_brand+'/pages',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

   // Método para cargar el Id del taller en Qcar
   getWorkshopQcarCompanyId(){
    return this.http.get(this.adminURL + 'workshop/' + this._ss.getWorkshopSettings().id_workshop,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para obtener el banner
  getInfoBanner(){
    if(this.banner != null){
      console.log("Banner cargado correctamente");
      return this.banner;
    }else{
      console.log("No hay banner informativo");
    }
  }

    //Método para obtener el banner inferior
    getInfoBannerUp(){
      if(this.banner_up != null){
        console.log("Banner cargado correctamente");
        return this.banner_up;
      }else{
        console.log("No hay banner informativo");
      }
    }

  //Método para obtener el banner inferior
  getInfoBannerBelow(){
    if(this.banner_below != null){
      console.log("Banner cargado correctamente");
      return this.banner_below;
    }else{
      console.log("No hay banner informativo");
    }
  }

  //Cargar todos las versiones de un modelo de tecdoc
  getTecdocVersions(id_brand, id_model){
    return this.http.get(this.driverURL + 'brand/'+id_brand+'/model/'+id_model+'/versions',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  //Cargar todos los modelos de una marca de tecdoc
  getTecdocModels(id){
    return this.http.get(this.driverURL + 'brand/'+id+'/models',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  //Cargar todas las marcas de tecdoc
  getTecdocBrands(){
    return this.http.get(this.driverURL + 'brands/',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Cargar los datos de una página
  getInfoPageDetails(id){
    return this.http.get(this.publicURL + 'pagedetail/' + id +'/workshop/' + this._ss.getWorkshopSettings().id_workshop + '/brand/'+ this._ss.getWorkshopSettings().id_brand,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para cargar los servicios de taller
  getWorkShopServices(){
    return this.http.get(this.publicURL + 'services/' + this._ss.getWorkshopSettings().id_workshop,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  //Método para cargar todos los vehículos de un taller
  getVehicles(){
    return this.http.get(this.publicURL + 'workshop/'+this._ss.getWorkshopSettings().id_workshop+'/brand/'+this._ss.getWorkshopSettings().id_brand+'/vehicles',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para obtener los detalles de un vehículo
  getVehicleDetails(id){
    return this.http.get(this.publicURL + 'vehicle/' + id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para cargar vehículos destacados
  getFeaturedVehicles(){
    return this.http.get(this.publicURL + 'workshop/'+this._ss.getWorkshopSettings().id_workshop+'/brand/'+this._ss.getWorkshopSettings().id_brand+'/vehicles/featured',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para cargar vehículos destacados
  getActiveVehicles(){
    return this.http.get(this.publicURL + 'workshop/'+this._ss.getWorkshopSettings().id_workshop+'/brand/'+this._ss.getWorkshopSettings().id_brand+'/vehicles/active',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Metodos de promociones
  getSectionPromotions() {
    return this.http.get(this.publicURL + 'promotions/available/' + this._ss.workShopConfig.id_workshop+'/brand/'+ this._ss.getWorkshopSettings().id_brand,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }
  
  // Método para obtener los detalles de una promocion
  getPromotionsDetails(id){
    return this.http.get(this.publicURL + 'promotion/' + id,
  { headers: this.getHeadersPublic() }).catch(this.handleError)
  .map((response: Response) => response.json()).catch(this.handleError);
  }

  //Método para cargar las promociones destacadas
  getFeaturedPromotions(){
    return this.http.get(this.publicURL + 'promotions/featured/' + this._ss.workShopConfig.id_workshop+ '/brand/'+ this._ss.getWorkshopSettings().id_brand,
  { headers: this.getHeadersPublic() }).catch(this.handleError)
  .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para obtener las aseguradoras
  getPartners(){
    return this.http.get(this.publicURL + 'workshop/' + this._ss.workShopConfig.id_workshop+'/brand/'+ this._ss.getWorkshopSettings().id_brand+'/partners',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para comprobar si el vehículo ya existe en el taller y devolver sus datos
  checkWorkShopPlate(plate){
    return this.http.get(this.driverURL + 'plate/' + plate + '/workshop/' + this._ss.workShopConfig.id_workshop,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }


  // Método para cargar los centros del Worhshop
  getWorkshopCenters(){
    return this.http.get(this.publicURL + 'workshop/'+this._ss.workShopConfig.id_workshop+'/centers',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Get Calendar Workshop
  getWorkShopCalendar(id_center, firstdayweek, timeshift){
    return this.http.put(this.driverURL + 'calendar', { id_center: id_center, firstdayweek: firstdayweek, timeshift: timeshift},
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para realizar el resguardo de cita
  confirmWorkShopAppointment(booking){
    return this.http.post(this.driverURL + 'booking/', {booking: booking},
   { headers: this.getHeadersPublic() }).catch(this.handleError)
   .map((response: Response) => response.json()).catch(this.handleError);
  }

  // Método para realizar el envio de email
  emailToWorkshopCenter(formdata){
      return this.http.post(this.publicURL + 'email/', {name: formdata.name, email: formdata.email, 
        phone: formdata.phone, from: formdata.email, to: formdata.center, message: formdata.message},
     { headers: this.getHeadersPublic() }).catch(this.handleError)
     .map((response: Response) => response.json()).catch(this.handleError);
  }

  getSocialNetworks(){
    return this.http.get(this.publicURL + '/workshop/'+ this._ss.getWorkshopSettings().id_workshop + '/brand/' +  this._ss.getWorkshopSettings().id_brand + '/socialnetworks',
   { headers: this.getHeadersPublic() }).catch(this.handleError)
   .map((response: Response) => response.json()).catch(this.handleError);
  }

  getBrandPromotions(){
    return this.http.get(this.publicURL + '/brand/promotions/'+ this._ss.getWorkshopSettings().id_brand,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  getWorkshopSlider(){
    return this.http.get(this.publicURL + '/workshop/'+ this._ss.getWorkshopSettings().id_workshop+'/brand/'+ this._ss.getWorkshopSettings().id_brand+'/slider',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  getWorkshopBanner(){
    return this.http.get(this.publicURL + '/workshop/'+ this._ss.getWorkshopSettings().id_workshop+'/brand/'+ this._ss.getWorkshopSettings().id_brand+'/banner/active',
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  getFeaturedBrandPromotions(){
    return this.http.get(this.publicURL + '/brand/promotions/featured/'+ this._ss.getWorkshopSettings().id_brand,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  getBrandPromotionDetail(id){
    return this.http.get(this.publicURL + '/brand/promotion/'+ id,
    { headers: this.getHeadersPublic() }).catch(this.handleError)
    .map((response: Response) => response.json()).catch(this.handleError);
  }

  public handleError(error: any) {    // log error
    console.log('Error en com service');
    console.log(error);
    // throw an application level error
    return Observable.throw(error.message || error.json());
  }
  
}




