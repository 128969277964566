<div class="content">
    <!-- Slider -->
    <!-- <app-banner-model-one *ngIf="promotions.length == 0" [banner]="bannerHome"></app-banner-model-one> -->
    <app-home-slider></app-home-slider>
    <!-- End Slider-->

    <div class="slider-container vehicle-slider-home" *ngIf="_ss.getModule(1)">
        <!-- Featured Vehicles -->
        <app-vehicle-slider></app-vehicle-slider>
        <!-- End Featured Vehicles -->
    </div>

    <!-- Star Bannner -->
    <app-banner-model-page-below [banner]="topBanner" [number]="'primero'" *ngIf="_ss.getModule(1)"></app-banner-model-page-below>
    <!-- End Banner -->


    <div style="margin-top:150px;" *ngIf="_ss.getModule(1) != true"></div>
    <div class="slider-container">
        <!-- Featured Promotions -->
        <app-promotion-slider></app-promotion-slider>
        <!-- End Featured Promotions -->
    </div>

    <!--
 <div class="banner-kitdigital">
        <app-banner-kitdigital></app-banner-kitdigital>
    </div>
    -->
   

    <!-- Star Bannner -->
    <app-banner-model-page-below [banner]="botBanner" [number]="'segundo'"></app-banner-model-page-below>
    <!-- End Banner -->

    <div class="slider-container">
        <!-- Slider Insurance -->
        <app-insurance-slider *ngIf="_ss.getModule(7)"></app-insurance-slider>
        <!-- End Slider Insurance-->
    </div>
</div>
