<div id="vehicle-container" *ngIf="vehicle">
    <div class="vehicle-slider">
        <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-container  *ngFor="let image of vehicle.images; let i = index" >
                <ng-template carouselSlide [id]="i">
                    <div class="image" [ngStyle]="{'background-image':'url('+ _ss.imgURL + image + ')'}"></div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
        <div class="slider-thumbs" [ngClass]="thumbNavigation ? 'show' : ''">
            <div class="btn-thumb">
                <a class="swing" (click)="showThumbs()">
                    <span *ngIf="!thumbNavigation">MOSTRAR MINIATURAS</span>
                    <span *ngIf="thumbNavigation">OCULTAR MINIATURAS</span>
                </a>
            </div>
            <owl-carousel-o [options]="thumbOptions">
                <ng-container *ngFor="let image of vehicle.images; let i = index">
                    <ng-template carouselSlide [id]="i">
                        <a (mouseover)="owlCar.to(image.toString())">
                            <div class="thumb"> 
                                <div class="image" [ngStyle]="{'background-image':'url('+ _ss.imgURL + image + ')'}"></div>
                            </div>
                        </a>
                    </ng-template>
                </ng-container>
            </owl-carousel-o> 
        </div>
    </div>
    
    <div class="content">
        <div id="vehicle-info">
            <div class="vehicle-title">
                <div class="title">
                    <h1>{{vehicle.brand}} {{vehicle.model}} {{vehicle.version}}</h1>
                    <span class="line"></span>
                </div>
            </div>

            <div class="vehicle-details">
                <h2>Detalles del vehículo</h2>
                <ul class="nav">
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="cardoor"></div>
                        <span>{{vehicle.bodywork}}</span>
                        <p class="details-name">Carrocería
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="cardoor"></div>
                        <span>{{vehicle.doors}}</span>
                        <p class="details-name">Puertas
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="fuel"></div>
                        <span>{{vehicle.fuel}}</span>
                        <p class="details-name">Combustible
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="earshift"></div>
                        <span>{{vehicle.transmission}}</span>
                        <p class="details-name">Transmisión
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="calendar"></div>
                        <span>{{vehicle.first_date_plate | date:'yyyy'}}</span>
                        <p class="details-name">Fecha Matriculación
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="kilometers"></div>
                        <span>{{vehicle.watios}}</span>
                        <p class="details-name">Potencia
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="road"></div>
                        <span>{{vehicle.kilometers | number:'':'es-ES'}} km</span>
                        <p class="details-name">Fecha Matriculación
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="engine"></div>
                        <span>{{vehicle.engine}}</span>
                        <p class="details-name">Motor
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="color"></div>
                        <span>{{vehicle.color}}</span>
                        <p class="details-name">Color
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="mechanic"></div>
                        <span>garantía {{vehicle.warranty}}</span>
                        <p class="details-name">Garantía
                            <span class="arrow"></span>
                        </p>
                    </li>
                    <!--
                    <li>
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="dgt"></div>
                        <span>{{vehicle.attributes.eco}}</span>
                    </li>
                    !-->
                </ul>
                <div class="comments" *ngIf="vehicle.description">
                    <h2>Comentarios del anunciante</h2>
                    <div class="description" *ngIf="vehicle.description" [innerHTML]="vehicle.description"></div>
                    <div class="description" *ngIf="!vehicle.description">
                        <p>¡Este vehículo no tiene comentarios del vendedor!</p>
                    </div>
                </div>
                <ul class="equipment">
                    <li [ngClass]="showEquipmentStatus ? 'active' : ''" (click)="showEquipmentStatus = true; showExtraStatus = false; showAccesoriesStatus = false">Equipamiento</li>
                    <li [ngClass]="showExtraStatus ? 'active' : ''" (click)="showEquipmentStatus = false; showExtraStatus = true; showAccesoriesStatus = false">Extras</li>
                    <li [ngClass]="showAccesoriesStatus ? 'active' : ''" (click)="showEquipmentStatus = false; showExtraStatus = false; showAccesoriesStatus = true">Accesorios</li>
                </ul>
                <div class="tab-content">
                    <div *ngIf="showEquipmentStatus">
                        <ul class="equipment-list" *ngIf="vehicle.equipments  && vehicle.equipments.length > 0">
                            <li *ngFor="let equipment of vehicle.equipments">
                                <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="check"></div>  {{equipment.Description}}
                            </li>
                        </ul>
                        <span *ngIf="!vehicle.equipments">¡Este vehículo no tiene equipamientos!</span>
                    </div>
                    <div *ngIf="showExtraStatus">
                        <ul class="extras-list" *ngIf="vehicle.extras && vehicle.extras.length > 0">
                            <li *ngFor="let extra of vehicle.extras">
                                <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="check"></div>  {{extra.Description}}
                            </li>
                        </ul>
                        <span *ngIf="!vehicle.extras">¡Este vehículo no tiene extras!</span>
                    </div>
                    <div *ngIf="showAccesoriesStatus">
                        <ul class="extras-list" *ngIf="vehicle.accesories && vehicle.accesories.length > 0">
                            <li *ngFor="let accesorie of vehicle.accesories">
                                <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="check"></div>  {{accesorie.descripcion}}
                            </li>
                        </ul>
                        <span *ngIf="!vehicle.accesories">¡Este vehículo no tiene accesorios!</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="vehicle-price">
            <ul class="tab">
                <li [ngClass]="!priceTabStatus ? 'active' : '' " (click)="priceTabStatus = !priceTabStatus">
                    <span>Sin entrada desde</span>
                    <span class=" price ">{{vehicle.financed_price}}/mes</span>
                </li>
                <li [ngClass]="priceTabStatus ? 'active' : '' " (click)="priceTabStatus = !priceTabStatus">
                    <span *ngIf="vehicle.offered_price">Ahorro de <b>{{discount | currency:'EUR':'symbol':'3.0'}}</b> ({{percentage}}%)</span>
                    <span *ngIf="!vehicle.offered_price">price al contado</span>
                    <span class="price" *ngIf="vehicle.offered_price">{{vehicle.offered_price | currency:'EUR':'symbol':'3.0'}}</span>
                    <span class="price" *ngIf="!vehicle.offered_price">{{vehicle.price | currency:'EUR':'symbol':'3.0'}}</span>
                </li>
            </ul>
            <div class="tab-content">

                <app-vehicle-calculator *ngIf="!priceTabStatus" [vehicle]="vehicle"></app-vehicle-calculator>
                <app-vehicle-contact *ngIf="priceTabStatus" [vehicle]="vehicle "></app-vehicle-contact>
            </div>
        </div>

    </div>
</div>
<app-vehicle-shop-now [ngClass]="!shopNowScrolled ? 'hidden' : '' " [vehicle]="vehicle "></app-vehicle-shop-now>