import { Component, OnInit } from '@angular/core';
import { WORKSHOP_ICONS } from '../../constants';
import { AppService } from 'src/app/services/app.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-workshop-services',
  templateUrl: './workshop-services.component.html',
  styleUrls: ['./workshop-services.component.scss']
})
export class WorkshopServicesComponent implements OnInit {


  collection = { count: 25, data: [] };
  services = [];
  service;
  page = {
    id_page_type : 2,
    name: null,
    banner_title: null,
    banner_image: null
  };
  config = {
    itemsPerPage: 9,
    currentPage: 1,
    totalItems: this.services.length
  };


  //Icon
  icons = WORKSHOP_ICONS;
  showService = false;
  close;

  constructor(
    private _ap: AppService,
    public _ss: SharingService) {
      this._ss.getLocalStorage();
      this._ss.checkSiteMaintenance();
      this._ss.setTitle('Servicios');
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  ngOnInit() {
    this.getInfoPage();
    this.close = this.icons.find(x => x.title == 'plus').pathIcon;
    this.getServices();
  }

  getInfoPage(){
    this._ap.getInfoPageDetails(this.page.id_page_type).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.page = response.data[0];
        console.log(this.page);
      }else{
        console.log(response.msg);
      }
    });
  }

  getServices(){
    this._ap.getWorkShopServices().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.services = response.data;
        console.log(this.services);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  showDetailsOfService(id){
    console.log("click");
    this.service = this.services.filter(x => x.id_service == id)[0];
    console.log(this.service);
    this.showService = true;
  }

  closeModalService(){
    this.showService = false;
    console.log("haciendo click sobre el boton de cierre");
    this.service = null;
  }

}
