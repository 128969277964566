
    <div class="container">
      <h1>Mapa del Sitio Web</h1>
      <ul class="sitemap">
        <li *ngFor="let li of _ss.navMenu; let i = index">
          <a [routerLink]="['/', li.url]">{{ li.title }}</a>
        </li>
        <li>
          <a routerLink="/accesibilidad">Accesibilidad</a>
        </li>
        <li>
          <a routerLink="/aviso-legal">Aviso Legal</a>
        </li>
        <li>
          <a routerLink="/terminos-y-condiciones">Términos y condiciones</a>
        </li>
        <li>
          <a routerLink="/politica-de-privacidad">Política de privacidad</a>
        </li>
      </ul>
    </div>

  