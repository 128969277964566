<div class="container">
    <h1>Accesibilidad</h1>
  <div class="content">
    <section>
      <h2>Compromiso de Accesibilidad</h2>
      <p>En esta página, nos comprometemos a garantizar la accesibilidad de nuestro sitio web para todos los usuarios. Estamos dedicados a cumplir con los estándares y pautas de accesibilidad web para crear una experiencia inclusiva para todos.</p>
    </section>

    <section>
      <h2>¿Qué es la Accesibilidad Web?</h2>
      <p>La accesibilidad web se refiere a la práctica de diseñar y desarrollar sitios web que puedan ser utilizados por todas las personas, incluyendo aquellas con discapacidades visuales, auditivas, motoras o cognitivas. El objetivo es garantizar que todas las personas tengan igualdad de acceso a la información y funcionalidad del sitio web.</p>
    </section>

    <section>
      <h2>Política de Accesibilidad</h2>
      <p>Nos comprometemos a cumplir con los estándares de accesibilidad establecidos por las WCAG (Web Content Accessibility Guidelines). Trabajamos continuamente para mejorar la accesibilidad de nuestro sitio web y proporcionar una experiencia accesible para todos los usuarios.</p>
    </section>

    <section>
      <h2>Recursos de Accesibilidad</h2>
      <ul>
        <li><a href="https://www.w3.org/WAI/WCAG21/quickref/">WCAG 2.1 Quick Reference</a></li>
        <li><a href="https://www.w3.org/WAI/demos/bad/overview.html">Demos de Accesibilidad Interactivos</a></li>
        <li><a href="https://www.w3.org/WAI/demos/bad/">Inclusión de Personas con Discapacidad</a></li>
      </ul>
    </section>

    <section>
      <h2>Contáctanos</h2>
      <p>Si tienes preguntas, comentarios o encuentras dificultades de accesibilidad en nuestro sitio web, no dudes en contactarnos:</p>
      <ul>
        <li>Teléfono: <a href="tel:+123456789">123456789</a></li>
        <li>Email: <a href="mailto:info@example.com">info@example.com</a></li>
      </ul>
    </section>

    <section>
      <h2>Actualizaciones de Accesibilidad</h2>
      <p>Estamos comprometidos en mejorar continuamente la accesibilidad de nuestro sitio web. Periódicamente realizamos actualizaciones y mejoras para cumplir con los estándares de accesibilidad y brindar una experiencia óptima para todos los usuarios.</p>
    </section>
    </div>
</div>
