import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SharingService } from 'src/app/services/sharing.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  centers = [];
  faPhone = faPhone;
  faEnvelope = faEnvelope;

  page = {
    id_page_type: 5,
    name: null,
    banner_title: null,
    banner_image: null,
    content_title: null,
    content_subtitle: null,
    content_description: null,
    content_image: null
  }

  model;
  sectionContact;
  
  submitted = false;
  emailForm: FormGroup;

  constructor(
    private _ap: AppService, 
    public _ss: SharingService,
    private formBuilder: FormBuilder
  ) { 
    this._ss.getLocalStorage();
    this._ss.checkSiteMaintenance(); 
    this._ss.setTitle('Contacto');
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      name: ['',Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['',Validators.required],
      center: ['',Validators.required],
      message: [''],
    });
    this.getInfoPage();
    this.getCenters();
  }
  get f() { return this.emailForm.controls; }

  getInfoPage(){
    this._ap.getInfoPageDetails(this.page.id_page_type).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.page = response.data[0];
        console.log(this.page);
      }else{
        console.log(response.msg);
      }
    });
  }

  getCenters(){
    console.log("devolviendo los centros");
    this._ap.getWorkshopCenters().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.centers = response.data;
        console.log(this.centers);
        this.centers = this.centers.filter(x => x.latitude != '');
        this.centers = this.centers.filter(x => x.longitude != '');
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  onSubmit(){

        this.submitted = true;

        // stop here if form is invalid
        if (this.emailForm.invalid) {
          Swal.fire({
            icon:'error',
            title: '¡Error!',
            text:"Algunos de los campos son obligatorios",
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2500
          });
            return;
        }
      
        console.log(this.emailForm.value);
        this.sendEmail();
  }

  

  sendEmail(){
    this._ap.emailToWorkshopCenter(this.emailForm.value).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        Swal.fire({
          icon:'success',
          title: 'Gracias '+this.emailForm.controls['name'].value+'!',
          text:"Hemos recibido correctamente su mensaje",
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      }else{
        console.log(response.msg);
        Swal.fire({
          icon:'error',
          title: 'Oops!',
          text:"Se ha producido un error al enviar el mensaje, inténtelo de nuevo más tarde",
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      }
    });
  }

  }


