<div class="modal-background" [ngStyle]="{'display':_ss.appointmentStatus ? 'flex' : 'none'}"></div>
<div class="appointment" [ngClass]="_ss.appointmentStatus ? 'appointment-fixed' : 'appointment-absolute'">
    <div *ngIf="_ss.appointmentStatus" class="close" (click)="closeAppointment()">
        <div class="icon" [inlineSVG]="plus"></div>
    </div>
    <!-- loader -->
    <div class="loader-box" *ngIf="_ss.loadingAppointmentStatus">
        <div class="loader"></div>
    </div>
    <!-- end loader-->


    <form name="form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <!-- plate search-->

    <!-- Vehicle Data Section -->
    <section id="vehicle">
        <div class="title" [ngStyle]="{'display':_ss.appointmentStatus ? 'flex' : 'none'}">
            <span class="number">1</span>¿Qué coche tengo?
        </div>
        <div class="plate-search">
            <div class="plate-input">
                <div class="plate-icon"></div>
                <input type="text" name="plate" formControlName="plate" (keyup)="getPlate()" placeholder="Matrícula" [ngClass]="{ 'is-invalid': submitted && f.plate.errors }" required  />
        
                <div *ngIf="appointment.vehicleData.id" class="clear-plate" (click)="clearVehicleData()">
                    <div class="icon" [inlineSVG]="plus"></div>
                </div>

                <div *ngIf="appointment.vehicleData.plate && appointment.vehicleData.id == null" class="clear-plate" (click)="clearPlate()">
                    <div class="icon" [inlineSVG]="plus"></div>
                </div>

                <div *ngIf="submitted && f.plate.errors" class="invalid-feedback">
                    <div *ngIf="f.plate.errors.required">* Campo obligatorio</div>
                </div>
            </div>
            <a class="btn-appointment" (click)="checkPlate()">
                <span *ngIf="!_ss.appointmentStatus">SOLICITAR CITA</span>
                <span *ngIf="_ss.appointmentStatus">BUSCAR</span>
            </a>
            <a *ngIf="_ss.appointmentStatus" class="btn-appointment" (click)="clearData()">LIMPIAR DATOS</a>
        </div>
        <!-- endf plate search-->
        <div class="vehicle-data" [ngStyle]="{'display':_ss.appointmentStatus ? 'flex' : 'none'}">

            <div class="form-group" [ngClass]="is_blocked ? 'disabled' : ''">
                <input type="text" list="brandlist" name="brand" formControlName="brand" (input)="changeBrand($event)" placeholder="Marca" [ngClass]="{ 'is-invalid': submitted && f.brand.errors }" required >
                <datalist id="brandlist">
                    <option *ngFor="let brand of brands" [value]="brand.name">{{brand.name}}</option>
                </datalist>
                <div *ngIf="appointment.vehicleData.brand && appointment.vehicleData.id == null" class="clear-plate" (click)="clearBrand()">
                    <div class="icon" [inlineSVG]="plus"></div>
                </div>
                <div *ngIf="submitted && f.brand.errors" class="invalid-feedback">
                    <div *ngIf="f.brand.errors.required">* Campo obligatorio</div>
                </div>
            </div>
            
            <div class="form-group" [ngClass]="is_blocked ? 'disabled' : ''">
                <input type="text" list="modellist" name="model" formControlName="model" (input)="changeModel($event)" placeholder="Modelo" [ngClass]="{ 'is-invalid': submitted && f.model.errors }" required />
                <datalist id="modellist">
                    <option *ngFor="let model of models" [value]="model.name" >{{model.name}}</option>
                </datalist>
                <div *ngIf="appointment.vehicleData.model && appointment.vehicleData.id == null" class="clear-plate" (click)="clearModel()">
                    <div class="icon" [inlineSVG]="plus"></div>
                </div>
                <div *ngIf="submitted && f.model.errors" class="invalid-feedback">
                    <div *ngIf="f.model.errors.required">* Campo obligatorio</div>
                </div>
            </div>
         
            <div class="form-group" [ngClass]="is_blocked ? 'disabled' : ''">
                <input type="text" list="versionslist" name="version" formControlName="version" (input)="changeVersion($event)" placeholder="Versión" [ngClass]="{ 'is-invalid': submitted && f.version.errors }" required />
                <datalist id="versionslist">
                    <option *ngFor="let version of versions" [value]="version.name" >{{version.name}}</option>
                </datalist> 
                <div *ngIf="appointment.vehicleData.version && appointment.vehicleData.id == null" class="clear-plate" (click)="clearVersion()">
                    <div class="icon" [inlineSVG]="plus"></div>
                </div>
                <div *ngIf="submitted && f.version.errors" class="invalid-feedback">
                    <div *ngIf="f.version.errors.required">* Campo obligatorio</div>
                </div>
            </div>
          
        </div>
    </section>
    <!-- end Vehicle Data Section -->

    <!-- Services Data Section -->
    <section id="services" [ngStyle]="{'display':_ss.appointmentStatus ? '' : 'none'}">
        <div class="title">
            <span class="number">2</span>¿Qué busco?
        </div>
        <div class="services">
            <ul>
                <li [ngClass]="getServiceStatus('revision') ? 'active' : ''" (click)="setServiceStatus('revision')">
                    <div class="icon" [inlineSVG]="revision"></div>
                    <span>Revisión</span>
                </li>
                <li [ngClass]="getServiceStatus('braking') ? 'active' : ''" (click)="setServiceStatus('braking')">
                    <div class="icon" [inlineSVG]="braking"></div>
                    <span>Frenado</span>
                </li>
                <li [ngClass]="getServiceStatus('tyre') ? 'active' : ''" (click)="setServiceStatus('tyre')">
                    <div class="icon" [inlineSVG]="tyre"></div>
                    <span>Neumáticos</span>
                </li>
                <li [ngClass]="getServiceStatus('distribution') ? 'active' : ''" (click)="setServiceStatus('distribution')">
                    <div class="icon" [inlineSVG]="distribution"></div>
                    <span>Distruibución</span>
                </li>
                <li [ngClass]="getServiceStatus('air_conditioning') ? 'active' : ''" (click)="setServiceStatus('air_conditioning')">
                    <div class="icon" [inlineSVG]="air_conditioning"></div>
                    <span>Climatización</span>
                </li>
                <li [ngClass]="getServiceStatus('shocks') ? 'active' : ''" (click)="setServiceStatus('shocks')">
                    <div class="icon" [inlineSVG]="shocks"></div>
                    <span>Amortiguadores</span>
                </li>
                <li [ngClass]="getServiceStatus('start') ? 'active' : ''" (click)="setServiceStatus('start')">
                    <div class="icon" [inlineSVG]="start"></div>
                    <span>Arranque</span>
                </li>
                <li [ngClass]="getServiceStatus('itv') ? 'active' : ''" (click)="setServiceStatus('itv')">
                    <div class="icon" [inlineSVG]="mechanic"></div>
                    <span>PreITV</span>
                </li>
                <li [ngClass]="getServiceStatus('bodywork') ? 'active' : ''" (click)="setServiceStatus('bodywork')">
                    <div class="icon" [inlineSVG]="bodywork"></div>
                    <span>Carrocería</span>
                </li>
            </ul>
        </div>
        <div class="remarks">
            <textarea name="remarks">o descríbenos lo que deseés</textarea>
            <div class="vehicles-additional">
                <div class="">
                    <label class="switch">
                        <input type="checkbox" (change)="setPickup($event)">
                        <span class="slider round"></span>
                    </label><span>Necesito recogida de <span class="red-color">vehículo</span></span>
                </div>
                <div class="">
                    <label class="switch">
                        <input type="checkbox" (change)="setCourtesyVehicle($event)">
                        <span class="slider round"></span>
                    </label> <span>Necesito un <span class="red-color">vehículo de cortesía</span></span>
                </div>
            </div>
        </div>

    </section>
    <!-- end Services Data Section -->

    <section id="pickup" [ngStyle]="{'display':appointment.pickup ? 'flex' : 'none'}">
        <div class="title">
            <span class="number">3</span> Datos de recogida:
        </div>
        <div class="pickup">
            <div class="">
                <div class="form-group">
                    <div class="">
                        <input type="text" name="streetName" formControlName="streetName" [(ngModel)]="appointment.pickupData.streetName"  (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" #search placeholder="Busca tu localización"  [ngClass]="{ 'is-invalid': submitted && f.streetName.errors }" [required]="appointment.pickup" />
                    </div>
                    
                    <div class="">
                        <input type="text" name="province" (keyup)="setProvince()" formControlName="province" placeholder="provincia"  [ngClass]="{ 'is-invalid': submitted && f.province.errors }" [required]="appointment.pickup" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-number">
                        <input type="text" name="number" (keyup)="setNumber()" formControlName="number" placeholder="Nº" [ngClass]="{ 'is-invalid': submitted && f.number.errors }" [required]="appointment.pickup" />
                    </div>
                  
                    <div class="">
                        <input type="text" name="council" (keyup)="setCouncil()" formControlName="council" placeholder="ciudad"  [ngClass]="{ 'is-invalid': submitted && f.council.errors }" [required]="appointment.pickup" />
                    </div>

                    <div class="">
                        <input type="text" name="postalCode" (keyup)="setPostalCode()" formControlName="postalCode" placeholder="C.P"  [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" [required]="appointment.pickup" />
                    </div>
                    
                </div>
            </div>

            <div class="">
                <label class="switch">
                    <input type="checkbox" (change)="setCrane($event)">
                    <span class="slider round"></span>
                </label> <span>Necesito una <span class="red-color">grúa</span></span>
            </div>
        
        </div>
    </section>

    <div class="map" [ngStyle]="{'display':appointment.pickup ? 'block' : 'none'}">
        <agm-map *ngIf="appointment.pickupData.latitude && appointment.pickupData.longitude" [latitude]=appointment.pickupData.latitude [longitude]=appointment.pickupData.longitude [zoom]="15">
            <agm-marker [latitude]="appointment.pickupData.latitude" [longitude]="appointment.pickupData.longitude" [label]="" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"> 
            </agm-marker>
        </agm-map>
    </div>

    <!-- Contact Data Section -->
    <section [ngStyle]="{'display':_ss.appointmentStatus ? 'flex' : 'none'}">
        <div class="title" *ngIf="!appointment.pickup">
            <span class="number">3</span>Datos de contacto
        </div>
        <div class="title" *ngIf="appointment.pickup">
            <span class="number">4</span>Datos de contacto
        </div>
        <div class="contact-name">
            <div class="">
                <input type="text" name="name" formControlName="name" placeholder="Nombre" (keyup)="getName()" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required/>
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">* Campo obligatorio</div>
                </div>
            </div>
            <div class="">
                <input type="text" name="firstsurname" formControlName="firstsurname" (keyup)="getSurname()" placeholder="Primer apellido" [ngClass]="{ 'is-invalid': submitted && f.firstsurname.errors }" required />
                <div *ngIf="submitted && f.firstsurname.errors" class="invalid-feedback">
                    <div *ngIf="f.firstsurname.errors.required">* Campo obligatorio</div>
                </div>
            </div>
            <div class="">
                <input type="text" name="secondsurname" formControlName="secondsurname" (keyup)="getSecondSurname()" placeholder="Segundo apellido" [ngClass]="{ 'is-invalid': submitted && f.secondsurname.errors }" />
                <div *ngIf="submitted && f.secondsurname.errors" class="invalid-feedback">
                    <div *ngIf="f.secondsurname.errors.required">* Campo obligatorio</div>
                </div>
            </div>             
        </div>
        <div class="contact-data">
            <div>
                <input type="text" name="phone" formControlName="phone" (keyup)="getPhone()" placeholder="Teléfono" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" required />
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                    <div *ngIf="f.phone.errors.required">* Campo obligatorio</div>
                </div>
            </div>
            <div>
                <input type="text" name="email" formControlName="email" (keyup)="getEmail()" placeholder="Correo" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">* Campo obligatorio</div>
                    <div *ngIf="f.email.errors.email">* Debe introducir un email correcto</div>
                </div>
            </div>
        </div>
        <agm-map [latitude]="appointment.pickupData.latitude" [longitude]="appointment.pickupData.longitude" [scrollwheel]="false" [zoom]="zoom">
            <agm-marker [latitude]="appointment.pickupData.latitude" [longitude]="appointment.pickupData.longitude"></agm-marker>
        </agm-map>
    </section>
    <!-- end Contact Data Section -->

    <!-- start Select Center -->
    <section *ngIf="centers && centers.length >= 1 && showCenters" [ngStyle]="{'display':_ss.appointmentStatus ? 'flex' : 'none'}">
        <div class="title" *ngIf="!appointment.pickup">
            <span class="number">4</span>¿A cual de nuestros centros deseas ir?
        </div>
        <div class="title" *ngIf="appointment.pickup">
            <span class="number">5</span>¿A cual de nuestros centros deseas ir?
        </div>
        <div class="row">
            <select name="center" formControlName="center" (change)="changeCenter($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.center.errors }" required >
                <option value="" disabled selected> Seleccione un centro</option>
                <option *ngFor="let center of centers" [value]="center.id_driver360_center">{{center.name}}</option>
            </select>
            <div *ngIf="submitted && f.center.errors" class="invalid-feedback">
                <div *ngIf="f.center.errors.required">* Campo obligatorio</div>
            </div>
        </div>
    </section>
    <!-- end Select Center -->

     <!-- Appointment Calendar -->
     <section *ngIf="calendar.length > 0" [ngStyle]="{'display':_ss.appointmentStatus ? 'flex' : 'none'}">
        <div class="title" *ngIf="centers.length > 1 && showCenters && !appointment.pickup || appointment.pickup && !showCenters">
            <span class="number">5</span>¿Cuando puedes venir?
        </div>
        <div class="title" *ngIf="centers.length == 1 || !appointment.pickup && !showCenters">
            <span class="number">4</span>¿Cuando puedes venir?
        </div>
        <div class="title" *ngIf="appointment.pickup && centers.length > 1 && showCenters">
            <span class="number">6</span>¿Cuando puedes venir?
        </div>
        <div class="calendar-navigation">
            <div class="calendar-status">
                <div class="">
                    <span class="status-available"></span> Hora no disponible
                </div>
                <div class="">
                    <span class="status-selected"></span> Hora seleccionada
                </div>
            </div>
            <div class="calendar-time">
                <span (click)="changeTimelineCalendar('morning')">Horario mañana</span>
                <span (click)="changeTimelineCalendar('afternoon')">Horario tarde</span>
            </div>
            <div class="calendar-info">
                <span><b>Primer día: </b>{{ firstday | date: 'EEEE d \'de\' MMMM' }}</span>
                <span><b>Último día: </b>{{ lastday | date: 'EEEE d \'de\' MMMM' }}</span>
            </div>
            <div class="calendar-today">
                <a (click)="getActualWeek()" *ngIf="actualday < firstday">Hoy</a>
            </div>
            <div class="calendar-nav">
                <a (click)="changeCalendarPrevWeek()" *ngIf="actualday < firstday">
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                </a>
                <a (click)="changeCalendarNextWeek()">
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </a>
            </div>
        </div>
        <div class="calendar">
            <table style="border:0;">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let day of calendar">
                            {{day.day}}
                            <span class="date ">{{day.timestamp | date: 'd \'de\'  MMMM'}}</span>
                        </th>
                    </tr>
                </thead>
            </table>
        </div>
        <div id="calendar" class="calendar" *ngIf="calendar[0].hours_remaining">
            <table>
                <tbody>
                    <ng-container *ngFor="let timeline of calendar[0].hours_remaining; let i = index">
                        <tr id="morning" class="afternoon" *ngIf="i == 0">
                            <td colspan="8">HORARIO DE MAÑANA</td>
                        </tr>
                        <tr id="afternoon" class="afternoon" *ngIf="timeline.hour == '15:30'">
                            <td colspan="8">HORARIO DE TARDE</td>
                        </tr>
                        <tr>
                            <td class="hour-colum">
                                {{timeline.hour}}
                            </td>
                            <td *ngFor="let day of calendar">
                                    <div *ngFor="let hour of day.hours_remaining">
                                        <div class="non-available" *ngIf="checkCanNotBooking(hour, timeline, day)"></div>
                                        <div [ngClass]="hour.appointment ? 'appointment-selected' : ''" class="promotion" *ngIf="hour.hour == timeline.hour && hour.available && actualday != day.timestamp" (click)="setAppointment(hour.hour, day.day, hour.start); hour.appointment = !hour.appointment">
                                            <!--
                                            <span class="flag-icon" *ngIf="hour.promotions.length > 0 ">Oferta</span>
                                            <span *ngFor=" let promotion of hour.promotions" class="offert_title">
                                            {{promotion.title}}
                                            </span>
                                            --> 
                                        </div>
                                    </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <input type="hidden" name="appointment" formControlName="appointment" [ngClass]="{ 'is-invalid': submitted && f.appointment.errors }" required />
        <div *ngIf="submitted && f.appointment.errors" class="invalid-feedback">
            <div *ngIf="f.appointment.errors.required">* Debe indicar un día y una hora para la cita</div>
        </div>
    </section>
    <!-- End Appointment Calendar -->

    <!-- Resume Appointment -->
    <section class="resume" *ngIf="_ss.appointmentStatus && appointment">
        <div class="title" *ngIf="calendar.length == 0 && !appointment.pickup || !appointment.pickup && !showCenters">
            <span class="number">5</span>Resument cita:
        </div>
        <div class="title" *ngIf="appointment.pickup && showCenters && calendar.length == 0 || !appointment.pickup && showCenters && calendar.length > 0 || appointment.pickup && !showCenters">
            <span class="number">6</span>Resument cita:
        </div>
        <div class="title" *ngIf="calendar.length > 0 && appointment.pickup && showCenters">
            <span class="number">7</span>Resument cita:
        </div>
        <div class="table-info">
            <div class="car-data car-info">
                <span class="title-category">Matrícula: <span>{{appointment.vehicleData.plate}}</span></span>
                <span class="title-category">Marca: <span>{{appointment.vehicleData.brand}}</span></span>
                <span class="title-category">Modelo: <span>{{appointment.vehicleData.model}}</span></span>
                <span class="title-category">Versión: <span>{{appointment.vehicleData.version}}</span></span>
            </div>
            <div class="car-data car-contact-data">
                <span class="title-category">Nombre: <span>{{appointment.contactData.name}}</span></span>
                <span class="title-category">Apellidos: <span>{{appointment.contactData.surname}}</span> <span>{{appointment.contactData.secondSurname}}</span></span>
                <span class="title-category">Teléfono: <span>{{appointment.contactData.phone}}</span></span>
                <span class="title-category">Email: <span>{{appointment.contactData.email}}</span></span>
            </div>

            <div class="car-data car-contact-data" *ngIf="appointment.pickup">
                <span class="title-category">Calle: <span>{{appointment.pickupData.streetName}}</span><span>, {{appointment.pickupData.number}}</span></span>
                <span class="title-category">Ciudad: <span>{{appointment.pickupData.council}}</span></span>
                <span class="title-category">Provincia: <span>{{appointment.pickupData.province}}</span></span>
                <span class="title-category">C.P: <span>{{appointment.pickupData.postalCode}}</span></span>
            </div>

            <div class="car-data car-appoinment">
                <span class="title-category">Dia: <span>{{appointment.bookingDate.timestamp | date: 'd \'de\' MMMM \'a las\' HH:mm'}}</span></span>
                <span class="title-category">Vehículo de cortesía: <span *ngIf="appointment.courtesy_vehicle">Si</span><span *ngIf="!appointment.courtesy_vehicle">No</span></span>
                <span class="title-category">Recogida: <span *ngIf="appointment.pickup">Si</span><span *ngIf="!appointment.pickup">No</span></span>
                <span class="title-category">Grua: <span *ngIf="appointment.pickupData.needsTow">Si</span><span *ngIf="!appointment.pickupData.needsTow">No</span></span>
            </div>
        </div>
    </section>
    <!-- End Resume Appointment-->

    <!-- Start Policy -->
    <section class="policy" *ngIf="_ss.appointmentStatus">
        <span>
            Nota: Le enviaremos un recordatiorio de la cita a su correo electrónico,<br /> si no puede acudir recuerde cancelar la cita llamando al <a href="tel:{{_ss.workShopConfig.phone}}">{{_ss.workShopConfig.phone}}</a>
        </span>
        <div class="">
            <label class="switch">
                <input type="checkbox" name="policy" formControlName="policy" [ngClass]="{ 'is-invalid': submitted && f.policy.errors }">
                <span class="slider round"></span>
            </label>
            <span>Acepto la <a href="" class="red-color">política de privacidad</a></span>
            <div *ngIf="submitted && f.policy.errors" class="invalid-feedback">
                * Debe aceptar la política de privacidad
            </div>
        </div>
    </section>

    <button *ngIf="appointmentStatus" class="btn-appointment">GENERAR SOLICITUD</button>
    </form>
</div>