import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import localeEs from '@angular/common/locales/es';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { VehicleDetailsComponent } from './components/vehicle-details/vehicle-details.component';
import { ContactComponent } from './components/contact/contact.component';
import { WorkshopServicesComponent } from './components/workshop-services/workshop-services.component';
import { HomeComponent } from './components/home/home.component';
import { WorkshopPromotionsComponent } from './components/workshop-promotions/workshop-promotions.component';
import { VehicleSliderComponent } from './components/shared/sliders/vehicle-slider/vehicle-slider.component';
import { InsuranceSliderComponent } from './components/shared/sliders/insurance-slider/insurance-slider.component';
import { BannerModelOneComponent } from './components/shared/banners/banner-model-one/banner-model-one.component';
import { AuthGuardService } from './services/auth-guard.service';
import { Routes, RouterModule } from '@angular/router';
import { SharingService } from './services/sharing.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { WorkshopDescriptionComponent } from './components/workshop-description/workshop-description.component';
import { BannerModelPageComponent } from './components/shared/banners/banner-model-page/banner-model-page.component';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';

// ngb module
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BannerModelPageBelowComponent } from './components/shared/banners/banner-model-page-below/banner-model-page-below.component';

// NGX PAGINATION
import { NgxPaginationModule } from 'ngx-pagination';
import { PromotionSliderComponent } from './components/shared/sliders/promotion-slider/promotion-slider.component';
import { WorkshopVehiclesComponent } from './components/workshop-vehicles/workshop-vehicles.component';
import { WorkshopAppointmentComponent } from './components/workshop-appointment/workshop-appointment.component';
import { PromotionDetailsComponent } from './components/promotion-details/promotion-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleShopNowComponent } from './components/vehicle-details/vehicle-shop-now/vehicle-shop-now.component';
import { VehicleCalculatorComponent } from './components/vehicle-details/vehicle-calculator/vehicle-calculator.component';
import { VehicleContactComponent } from './components/vehicle-details/vehicle-contact/vehicle-contact.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpModule } from '@angular/http';
import { MapComponent } from './components/contact/map/map.component';
import { registerLocaleData } from '@angular/common';
import { WorkshopPoliciesComponent } from './components/workshop-policies/workshop-policies.component';
import { AppConfigService } from './services/app-config.service';
import { HomeSliderComponent } from './components/shared/sliders/home-slider/home-slider.component';
import { UnderContructionComponent } from './components/under-contruction/under-contruction.component';
import { EmbedVideo } from 'ngx-embed-video';
import { NavComponent } from './components/shared/nav/nav.component';
import { BannerKitdigitalComponent } from './components/shared/banners/banner-kitdigital/banner-kitdigital.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { AccesibilityComponent } from './components/accesibility/accesibility.component';

const routes: Routes = [
  { path: 'inicio', component: HomeComponent },
  { path: 'quienes-somos', component: WorkshopDescriptionComponent },
  { path: 'servicios', component: WorkshopServicesComponent },
  { path: 'vehiculos', component: WorkshopVehiclesComponent },
  { path: 'vehiculos/:id', component: VehicleDetailsComponent },
  { path: 'promociones', component: WorkshopPromotionsComponent },
  { path: 'promociones/:id', component: PromotionDetailsComponent },
  { path: 'promociones-marca/:id', component: PromotionDetailsComponent },
  { path: 'contacto', component: ContactComponent },
  { path: 'politica-de-privacidad', component: WorkshopPoliciesComponent },
  { path: 'terminos-y-condiciones', component: WorkshopPoliciesComponent },
  { path: 'aviso-legal', component: WorkshopPoliciesComponent },
  { path: 'mapa-web', component: SitemapComponent},
  { path: 'accesibilidad', component: AccesibilityComponent},
  { path: '404', component: UnderContructionComponent },
  //{ path: 'configuracion', component: ContactComponent, canActivate: [AuthGuardService]},
  { path: '**', pathMatch: 'full', redirectTo: '/inicio' },
];

registerLocaleData(localeEs, 'es');

// La función exportada para ejecutar los proveedores antes que arranque angular
export function initConfig(appConfigService: AppConfigService) {
  return () => appConfigService.loadConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    VehicleDetailsComponent,
    PromotionDetailsComponent,
    ContactComponent,
    WorkshopServicesComponent,
    WorkshopVehiclesComponent,
    HomeComponent,
    WorkshopPromotionsComponent,
    VehicleSliderComponent,
    InsuranceSliderComponent,
    BannerModelOneComponent,
    WorkshopDescriptionComponent,
    BannerModelPageComponent,
    BannerModelPageBelowComponent,
    PromotionSliderComponent,
    WorkshopAppointmentComponent,
    VehicleShopNowComponent,
    VehicleCalculatorComponent,
    VehicleContactComponent,
    MapComponent,
    WorkshopPoliciesComponent,
    HomeSliderComponent,
    UnderContructionComponent,
    NavComponent,
    BannerKitdigitalComponent,
    SitemapComponent,
    AccesibilityComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    InlineSVGModule.forRoot(),
    FontAwesomeModule,
    NgbModule,
    BrowserAnimationsModule,
    CarouselModule,
    EmbedVideo.forRoot(),
    MatAutocompleteModule,
    AgmCoreModule.forRoot({
      //apiVersion: 'xxx', // optional
      //channel: 'yyy', // optional
      apiKey: 'AIzaSyB_pDhXSS3Sw2dbxPaB9TdOpqPK9n0zbjQ',
      language: 'es',
      libraries: ['geometry', 'places'],
    }),
    NgxPaginationModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'ignore'}),
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ],
  providers: [
    SharingService,
    AuthGuardService,
    AppConfigService,
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService, SharingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
